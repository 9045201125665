import React, { FC, useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import moment from 'moment'
import { Dropdown, Modal } from 'react-bootstrap-v5'
import { CustomerID, UserModel } from '../../../types'
import { RootState } from '../../../setup'
import { KTSVG } from '../../../_metronic/helpers'
import { requestServiceNow } from '../../services/service'
import { getErrorMessage } from '../../../helper'
import toast from 'react-hot-toast'

type Props = {
  show: boolean
  onHide: () => void
}
const GetServiceNowModal:FC<Props> = ({ show, onHide }) => {
  const [userData, setUserData] = useState<UserModel>()
  const [submit, setSubmit] = useState<boolean>(false)
  const [validate, setValidate] = useState<boolean>(true)
  const [customer, setCustomer] = useState<CustomerID>({ customerId: '', companyName: '' })
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const handleSubmit = () => {
    if (firstName === '' || lastName === '' || phoneNumber === '' || email === '' || description === '') {
      setValidate(false)
      return
    }

    setSubmit(true)

    requestServiceNow({ customer, firstName, lastName, phoneNumber, email, description })
    .then((res) => {
      setSubmit(false)
      toast.success(res.data)
      onHide()
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }

  useEffect(() => {
    setUserData(user)
    setFirstName(user.firstname)
    setLastName(user.lastname)
    setPhoneNumber(user.primaryPhone ? user.primaryPhone : '')
    setEmail(user.email)
    setDescription('')

    let primaryPhone = ''

    if (user.primaryPhone) {
      if (user.primaryPhone === 'work_phone')
        primaryPhone = user.workPhone ? user.workPhone : ''
      else if (user.primaryPhone === 'work_cell')
        primaryPhone = user.workCell ? user.workCell : ''
      else
        primaryPhone = user.other ? user.other : ''
    }

    setPhoneNumber(primaryPhone)

    if (user?.customerIds)
      setCustomer(user?.customerIds[0])
  }, [user, show])

  return (
    <Modal size='lg' 
      show={show}
      dialogClassName="w-50"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id="contained-modal-title-vcenter">
          <div className='d-flex justify-content-between'>
            <h2 className='d-flex align-items-center'>Request Service - 
              <Dropdown className='ms-3'>
                <Dropdown.Toggle id="dropdown-basic" variant="light" size="sm">
                  { customer.customerId } - { customer.companyName }
                </Dropdown.Toggle>
                <Dropdown.Menu className='mt-1'>
                  {
                    userData?.customerIds?.map((c, index) => {
                      return (
                        <Dropdown.Item
                          onClick={() => setCustomer(c)}
                          key={index}
                        >
                          { c.customerId} - { c.companyName }
                        </Dropdown.Item>
                      )
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </h2>
            <span className='text-hover-primary cursor-pointer' onClick={onHide}>
              <KTSVG
                path='/media/icons/duotone/Navigation/Close.svg'
                className='svg-icon-1'
              />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
          <div className='mb-7'>
            <label className='fw-bold fs-6 mb-2'>Date of Service Request: {moment().format('DD/MM/YY')}</label>
          </div>
          <div className='mb-7 d-flex align-items-center justify-content-between'>
            <label className='fw-bold fs-6 mb-2 pt-1 w-25 required'>Full Name: </label>
            <input
              type='text'
              className='select2-search__field w-50'
              placeholder='First Name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <input
              type='text'
              className='select2-search__field ms-3 w-50'
              placeholder='Last Name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className='mb-7 d-flex align-items-center justify-content-between'>
            <label className='fs-6 mb-2 pt-1 w-25 required'>Phone / Email:</label>
            <input
                type='text'
                className='select2-search__field w-50'
                placeholder='Phone number'
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              <input
                type='text'
                className='select2-search__field ms-3 w-50'
                placeholder='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
          </div>
          <div className='mb-7'>
            <label className='fs-6 mb-2 pt-1 required'>Description of Service Request:</label>
            <textarea
              className='select2-search__field w-100'
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className={`mb-7 ${validate && 'd-none'}`}>
            <label className='fs-6 text-danger'>Please fill the all required fields</label>
          </div>
          <div className='text-center pt-15'>
            <button
              type='button'
              className='btn btn-light me-3'
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              type='submit'
              className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}
              onClick={handleSubmit}
            >
              Submit
              {
                submit &&
                <div
                  className="spinner-border w-17px h-17px ms-2 text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              }
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { GetServiceNowModal }