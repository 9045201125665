import React from 'react'
import { Dropdown } from 'react-bootstrap-v5'
import { QuoteModel } from '../../../../types'

type Props = {
  data: QuoteModel
}

const ActionDropDown: React.FC<Props> = (props) => {
  const { data } = props
  return (
    <Dropdown key={data.dbkey}>
      <Dropdown.Toggle id="dropdown-basic" variant="light" size="sm">
        Actions
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          href={`${process.env.REACT_APP_ONLINE_URL}/test/nbsonline/processing_pdf.php?dbkey_quote_b=${data.dbkey}&dbkey_calreports=&order_type=quote&pdf=y`}
          target="_blank"
        >
          View
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ActionDropDown }